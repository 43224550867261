import { render, staticRenderFns } from "./TheUpgradeToNuvolosOption.vue?vue&type=template&id=4975487c&"
import script from "./TheUpgradeToNuvolosOption.vue?vue&type=script&lang=js&"
export * from "./TheUpgradeToNuvolosOption.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4975487c')) {
      api.createRecord('4975487c', component.options)
    } else {
      api.reload('4975487c', component.options)
    }
    module.hot.accept("./TheUpgradeToNuvolosOption.vue?vue&type=template&id=4975487c&", function () {
      api.rerender('4975487c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/invitations/components/TheUpgradeToNuvolosOption.vue"
export default component.exports