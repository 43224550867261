var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", fluid: "" } },
    [
      _c(
        "v-alert",
        { attrs: { type: "info", prominent: "", text: "", border: "left" } },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column subtitle-2 primary--text" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Existing Alphacruncher Account")
              ]),
              _vm._v(
                " Your email address exists in our system but it does not belong to any registered Nuvolos user. To sign up, you can upgrade your Alpacruncher account to Nuvolos account. "
              )
            ]
          )
        ]
      ),
      _c(
        "v-btn",
        {
          staticClass: "mt-4 upgrade-to-nuvolos",
          staticStyle: { width: "100%" },
          attrs: {
            loading: _vm.upgradingToNuvolos,
            color: "primary",
            outlined: ""
          },
          on: {
            click: function($event) {
              return _vm.upgradeToNuvolosAccount()
            }
          }
        },
        [
          _c("span", { staticStyle: { "max-width": "100%" } }, [
            _vm._v("Upgrade to Nuvolos account")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }